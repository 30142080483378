import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

// Note: when merging classes in components,
// use a custom property for passing classes, as
// default 'class' attribute will still be added after merging:
/**
 * // Can be merged
 * <some-component custom-class="w-4 text-base" />
 *
 * // Can NOT be merged
 * <some-component class="w-4 text-base" />
 */
export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs))
